
.badge-muted {
  @if ($duke-color-scheme == "light") {
    color: lighten($text-muted, 10%);
    background-color: darken($card-bg, 10%);
  } @else {
    color: darken($text-muted, 10%);
    background-color: lighten($card-bg, 10%);
  }
  font-style: italic;
}

.invalid-feedback {
  color: duke_contrasted-color(theme-color(danger), $card-bg);
}
.valid-feedback {
  color: duke_contrasted-color(theme-color(success), $card-bg);
}
.info-feedback {
  color: duke_contrasted-color(theme-color(info), $card-bg);
}
/**
 * Form validity
 * As the validity is updated on input blur/change, and the feedback is displayed as part of DOM flow
 * we must add a transition animation to delay it, so we can ensure that a click target will not move
 * out of the way as the feedback is displayed.
 */
.was-validated .form-control:invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-feedback,
.checkbox-group.is-invalid > .invalid-feedback,
.feedback.info-feedback
{
  // max-height needs to be sufficient to fit all error messages in the narrowest imaginable space,
  // but as low as possible to keep animation smooth. Current formula is for 3 lines.
  transform: scale(1);
  $relative-font-size: $form-feedback-font-size / 100%;
  max-height: calc(3 * #{$font-size-base} * #{$line-height-base} * #{$relative-font-size});
}
.form-control:disabled, .form-control[readonly]
{
  cursor: default;
}
.feedback
{
  @include transition(all 0.2s ease-in-out 0.1s);
  transform-origin: 100% 0;
  overflow-y: auto;
  display: block;
  $relative-font-size: $form-feedback-font-size / 100%;
  max-height: calc(1 * #{$font-size-base} * #{$line-height-base} * #{$relative-font-size});
  margin-top: $form-feedback-margin-top;
  margin-bottom: -1 * $form-group-margin-bottom + $form-feedback-margin-top;

  z-index: 1;
  position: relative;
}
.feedback.hidden
{
  transform: scale(0)!important;
}
.invalid-feedback {
  transform: scale(0);
}
.form-control.is-invalid ~ .invalid-feedback + .form-text,
.form-control.is-invalid ~ .invalid-feedback + .invalid-feedback
{
  margin-top: $form-group-margin-bottom - $form-feedback-margin-top;
  margin-bottom: -1 * $form-group-margin-bottom + $form-feedback-margin-top;
}
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid,
.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  // override BS mixin and set postion to bottom
  background-position: bottom $input-height-inner-quarter right $input-height-inner-quarter;
}

/**
 * Form validity
 */
.checkbox-group > .invalid-feedback,
.custom-checkbox .invalid-feedback {
  border-top: $input-border-width solid $danger;
  margin-top: calc(#{$form-feedback-margin-top} - #{$input-border-width});
}

.custom-checkbox
{
  .custom-control-label
  {
    width: 100%;
  }
  .invalid-feedback
  {
    width: calc(100% + #{$custom-control-gutter} + #{$custom-control-indicator-size});
    margin-left: calc(-1 * (#{$custom-control-gutter} + #{$custom-control-indicator-size}));
  }
  & .custom-control-input.is-valid ~ .custom-control-label,
  & .custom-control-input.is-invalid ~ .custom-control-label
  {
    background-repeat: no-repeat;
    background-position: center right $input-height-inner-quarter;
    background-size: $input-height-inner-half $input-height-inner-half;
    padding-right: $input-height-inner;
  }
  & .custom-control-input.is-valid ~ .custom-control-label
  {
    background-image: $form-feedback-icon-valid;
  }
  & .custom-control-input.is-invalid ~ .custom-control-label
  {
    background-image: $form-feedback-icon-invalid;
  }
}
.form-group
{
  position:relative;
}

.input-group > .input-group-input-wrapper
{
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;
  // allows the element to shrink below content width
  width: 0;
  min-height: $input-height;
  border-radius: $input-border-radius;
  + .input-group-input-wrapper {
    margin-left: -$input-border-width;
  }
  &:not(:last-child) { @include border-right-radius(0); }
  &:not(:first-child) { @include border-left-radius(0); }
  & > .form-control
  {
    border-radius: inherit;
  }

}
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text
{
  height: $input-height;
}
.input-group-lg > .input-group-input-wrapper >.form-control:not(textarea),
.input-group-lg > .input-group-input-wrapper > .custom-select,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text {
  height: $input-height-lg;
}
.input-group-lg > .input-group-input-wrapper  {
  min-height: $input-height-lg;
  @include border-radius($input-border-radius-lg);
  > .form-control,
  > .custom-select {
    padding: $input-padding-y-lg $input-padding-x-lg;
    @include font-size($input-font-size-lg);
    line-height: $input-line-height-lg;

  }
}
.input-group-sm > .input-group-input-wrapper >.form-control:not(textarea),
.input-group-sm > .input-group-input-wrapper > .custom-select,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text {
  height: $input-height-sm;
}
.input-group-sm > .input-group-input-wrapper {
  min-height: $input-height-sm;
  @include border-radius($input-border-radius-sm);
  > .form-control,
  > .custom-select {
    padding: $input-padding-y-lg $input-padding-x-sm;
    @include font-size($input-font-size-sm);
    line-height: $input-line-height-sm;
  }
}

.input-group-lg > .input-group-input-wrapper > .custom-select,
.input-group-sm > .input-group-input-wrapper > .custom-select {
  padding-right: $custom-select-padding-x + $custom-select-indicator-padding;
}

.placeholder-label
{
  position: absolute;
  margin: 0;
  color: duke_contrasted-color($input-placeholder-color, $input-bg);
  transform-origin: 0;
  @include transition($transition-base);
  font-size: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  left: calc(#{$input-border-width} + (#{$input-padding-x} / 2));
  top: calc(#{$input-border-width} + #{$input-padding-y});
  height: $line-height-base;
  padding: 0 ($input-padding-x / 2);
  max-width: calc(100% - #{$input-padding-x} - (#{$input-border-width} * 2));
  z-index: 0;
  .input-group ~ &,
  .input-group &
  {
    z-index: 3;
  }
  &:before
  {
    content:"";
    position: absolute;
    /*
     * used for dynamically switching box-shadow color in all size variants
     */
    color: $card-bg;
    background-color: $input-bg;
    top: 0;
    bottom: $input-border-width;
    left: $input-border-width;
    right: $input-border-width;
    border-radius: 0 0 calc(#{$input-border-radius} - #{$input-border-width}) calc(#{$input-border-radius} - #{$input-border-width});
    z-index: -1;
  }
}

/**
 * BAckground variant syuport for the placeholder label on top
 */
@each $color, $value in $theme-colors {
  .alert-#{$color} {
    $bg: duke_theme-color-level-mix($color, $alert-bg-level);
    $fg: duke_contrasted-color(
        theme-color-level($color, $alert-color-level),
        $bg,
        $duke-alert-style == "solid" or
      ($duke-color-scheme == "dark" and
        ($duke-alert-style == "solid-icon" or $duke-alert-style == "faded"))
    );
    .form-control.has-value ~ .placeholder-label,
    .form-control:focus ~ .placeholder-label,
    .placeholder-label.on-top
    {
      color: $fg;
    }
    & .placeholder-label:before {
      color: $bg;
    }
  }
  .bg-#{$color} {

    $bg: $value;
    $fg: duke_contrasted-color(
        $input-placeholder-color,
        $bg
    );
    .form-control.has-value ~ .placeholder-label,
    .form-control:focus ~ .placeholder-label,
    .placeholder-label.on-top
    {
      color: $fg !important;
    }
    & .placeholder-label:before {
      color: $bg !important;
    }
  }
}


.disabled .placeholder-label,
.readonly .placeholder-label,
*[disabled] ~ .placeholder-label,
*[readonly] ~ .placeholder-label
{
  color: duke_contrasted-color($input-placeholder-color, $input-disabled-bg);
  &:before {
    background-color: $input-disabled-bg;
  }
}
.form-control
{
  &:disabled,
  &[readonly] {
    color: duke_contrasted-color($input-placeholder-color, $input-disabled-bg);
  }
}

.form-control.is-invalid ~ .placeholder-label,
.form-control.is-valid ~ .placeholder-label,
{
  // create space for icon
  max-width: calc(100% - 1.5em - #{$input-padding-x} - (#{$input-border-width} * 2));
}
.form-control-lg.is-invalid ~ .placeholder-label,
.form-control-lg.is-valid ~ .placeholder-label,
.input-group-lg .is-invalid .placeholder-label,
.input-group-lg .is-valid .placeholder-label
{
  max-width: calc(100% - 1.5em - #{$input-padding-x-lg} - (#{$input-border-width} * 2));
}

.form-control-sm.is-invalid ~ .placeholder-label,
.form-control-sm.is-valid ~ .placeholder-label,
.input-group-sm .is-invalid .placeholder-label,
.input-group-sm .is-valid .placeholder-label
{
  max-width: calc(100% - 1.5em - #{$input-padding-x-sm} - (#{$input-border-width} * 2));
}

.form-control-lg ~ .placeholder-label,
.input-group-lg .placeholder-label
{
  max-width: calc(100% - #{$input-padding-x-lg} - (#{$input-border-width} * 2));
  font-size:$input-font-size-lg;
  height: $input-line-height-lg * 1em;
  left: calc(#{$input-border-width} + (#{$input-padding-x-lg} / 2));
  top: calc(#{$input-border-width} + #{$input-padding-y-lg});
  padding: 0 ($input-padding-x-lg / 2);
  &:before {
    border-radius: 0 0 calc(#{$input-border-radius-lg} - #{$input-border-width}) calc(#{$input-border-radius-lg} - #{$input-border-width});
  }
  &:after {
    border-radius: 0 0 $input-border-radius-lg $input-border-radius-lg;
  }
}
.form-control-sm ~ .placeholder-label,
.input-group-sm .placeholder-label
{
  max-width: calc(100% - #{$input-padding-x-sm} - (#{$input-border-width} * 2));
  font-size:$input-font-size-sm;
  height: $input-line-height-sm * 1em;
  left: calc(#{$input-border-width} + (#{$input-padding-x-sm} / 2));
  top: calc(#{$input-border-width} + #{$input-padding-y-sm});
  padding: 0 ($input-padding-x-sm / 2);
  &:before {
    border-radius: 0 0 calc(#{$input-border-radius-sm} - #{$input-border-width}) calc(#{$input-border-radius-sm} - #{$input-border-width});
  }
  &:after {
    border-radius: 0 0 $input-border-radius-sm $input-border-radius-sm;
  }
}

.form-control.has-value ~ .placeholder-label,
.form-control:focus ~ .placeholder-label,
.placeholder-label.on-top
{
  max-width: calc(100% - #{$input-padding-x} - (#{$input-border-width} * 2));

  top: calc(1em * (#{$line-height-base} / -2));
  // transform: scale(0.75);
  font-size: $input-font-size * 0.75;

  &:before {
    background-color: currentcolor;
  }
  color: duke_contrasted-color($input-placeholder-color, $card-bg);
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border: $input-border-width solid $input-border-color;
    border-top: none;
    border-radius: 0 0 $input-border-radius $input-border-radius;
    height: calc(50% - #{$input-border-width});
  }
}
.form-control-lg.has-value ~ .placeholder-label,
.form-control-lg:focus ~ .placeholder-label,
.form-control-lg ~ .placeholder-label.on-top,
.input-group-lg .form-control.has-value ~ .placeholder-label,
.input-group-lg .form-control:focus ~ .placeholder-label,
.input-group-lg .form-control ~ .placeholder-label.on-top
{
  max-width: calc(100% - #{$input-padding-x-lg} - (#{$input-border-width} * 2));

  font-size: $input-font-size-lg * 0.75;
  height: $input-line-height-lg * 1em;
  top: calc(1em * (#{$input-line-height-lg} / -2));
  &:before {
    border-radius: 0 0 calc(#{$input-border-radius-lg} - #{$input-border-width}) calc(#{$input-border-radius-lg} - #{$input-border-width});
  }
  &:after {
    border-radius: 0 0 $input-border-radius-lg $input-border-radius-lg;
  }
}
.form-control-sm.has-value ~ .placeholder-label,
.form-control-sm:focus ~ .placeholder-label,
.form-control-sm ~ .placeholder-label.on-top,
.input-group-sm .form-control.has-value ~ .placeholder-label,
.input-group-sm .form-control:focus ~ .placeholder-label,
.input-group-sm .form-control ~ .placeholder-label.on-top
{
  max-width: calc(100% - #{$input-padding-x-sm} - (#{$input-border-width} * 2));

  font-size: $input-font-size-sm * 0.75;
  height: $input-line-height-sm * 1em;
  top: calc(1em * (#{$input-line-height-sm} / -2));
  &:before {
    border-radius: 0 0 calc(#{$input-border-radius-sm} - #{$input-border-width}) calc(#{$input-border-radius-sm} - #{$input-border-width});
  }
  &:after {
    border-radius: 0 0 $input-border-radius-sm $input-border-radius-sm;
  }
}

.form-control:focus ~ .placeholder-label
{
  &:before {
    box-shadow: 0px #{($input-font-size * 0.75 * $input-line-height / 2) - 0.2rem} 0px 0px currentcolor inset, 0 0 0 ($input-focus-width / 2) rgba($input-btn-focus-color, .25) inset;
  }
  &:after {
    border-color: $input-focus-border-color;
  }
  // 0px 5.85px 0px 0px white inset, 0 0 0 0.15rem rgba(118, 171, 13, 0.25) inset
}
.form-control.is-valid
{
  &:focus ~ .placeholder-label:before {
    box-shadow: 0px #{($input-font-size * 0.75 * $input-line-height / 2) - 0.2rem} 0px 0px currentcolor inset, 0 0 0 ($input-focus-width / 2) rgba(map-get(map-get($form-validation-states, valid), color), .25) inset;
  }
  & ~ .placeholder-label:after {
    border-color: map-get(map-get($form-validation-states, valid), color);
  }
}
.form-control.is-invalid
{
  &:focus ~ .placeholder-label:before {
    box-shadow: 0px #{($input-font-size * 0.75 * $input-line-height / 2) - 0.2rem} 0px 0px currentcolor inset, 0 0 0 ($input-focus-width / 2) rgba(map-get(map-get($form-validation-states, invalid), color), .25) inset;
  }
  & ~ .placeholder-label:after {
    border-color: map-get(map-get($form-validation-states, invalid), color);
  }
}

.form-control-lg:focus ~ .placeholder-label:before,
.input-group-lg .form-control:focus ~ .placeholder-label:before
{
  box-shadow: 0px #{($input-font-size-lg * 0.75 * $input-line-height / 2) - 0.2rem} 0px 0px currentcolor inset, 0 0 0 ($input-focus-width / 2) rgba($input-btn-focus-color, .25) inset;
}
.form-control-lg.is-valid:focus  ~ .placeholder-label:before,
.input-group-lg .form-control.is-valid:focus  ~ .placeholder-label:before
{
  box-shadow: 0px #{($input-font-size-lg * 0.75 * $input-line-height / 2) - 0.2rem} 0px 0px currentcolor inset, 0 0 0 ($input-focus-width / 2) rgba(map-get(map-get($form-validation-states, valid), color), .25) inset;
}

.form-control-lg.is-invalid:focus  ~ .placeholder-label:before,
.input-group-lg .form-control.is-invalid:focus  ~ .placeholder-label:before
{
  box-shadow: 0px #{($input-font-size-lg * 0.75 * $input-line-height / 2) - 0.2rem} 0px 0px currentcolor inset, 0 0 0 ($input-focus-width / 2) rgba(map-get(map-get($form-validation-states, invalid), color), .25) inset;
}
.form-control-sm:focus ~ .placeholder-label:before,
.input-group-sm .form-control:focus ~ .placeholder-label:before
{
  box-shadow: 0px #{($input-font-size-sm * 0.75 * $input-line-height-sm / 2) - 0.2rem} 0px 0px currentcolor inset, 0 0 0 ($input-focus-width / 2) rgba($input-btn-focus-color, .25) inset;
}
.form-control-sm.is-valid:focus  ~ .placeholder-label:before,
.input-group-sm .form-control.is-valid:focus  ~ .placeholder-label:before
{
  box-shadow: 0px #{($input-font-size-sm * 0.75 * $input-line-height-sm / 2) - 0.2rem} 0px 0px currentcolor inset, 0 0 0 ($input-focus-width / 2) rgba(map-get(map-get($form-validation-states, valid), color), .25) inset;
}

.form-control-sm.is-invalid:focus  ~ .placeholder-label:before,
.input-group-sm .form-control.is-invalid:focus  ~ .placeholder-label:before
{
  box-shadow: 0px #{($input-font-size-sm * 0.75 * $input-line-height-sm / 2) - 0.2rem} 0px 0px currentcolor inset, 0 0 0 ($input-focus-width / 2) rgba(map-get(map-get($form-validation-states, invalid), color), .25) inset;
}
select.with-placeholder-label
{
  text-indent: -100%;
  &.has-value,
  &:focus
  {
    text-indent: 0;
  }

}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label
{
  color: duke_contrasted-color($danger, $card-bg);
}
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label
{
  color: duke_contrasted-color($success, $card-bg);
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before
{

  border-color: $danger;
  background-color: $danger;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before
{

  border-color: $success;
  background-color: $success;
}

/**
 * Toggle the show/hide password feature on/off for the whole application.
 */
$duke-enable-toggle-pwd: true!default;
:root {
  /**
   * Define default css variables based on the set scss variable value. Individual inputs can then be overridden with
   * custom styles by setting overriding css variable values with a more specific selector path. Only the combinations
   * listed below are allowed.
   */
  @if($duke-enable-toggle-pwd) {
    // With these variables the toggle is shown
    --duke-toggle-pwd-size: 1;
    --duke-toggle-pwd-display: initial;
    // MS has their own built in control that messes up everything, so we must hide it
    --duke-browser-toggle-pwd-display: none;
  } @else {
    // With these variables the toggle is not shown
    --duke-toggle-pwd-size: 0;
    --duke-toggle-pwd-display: none;
    --duke-browser-toggle-pwd-display: initial;
  }
}
@if ($enable-validation-icons) {
  input.is-password {
    &.is-invalid,
    &.is-valid {
      // extend the padding to make room for the toggle button
      padding-right: calc(#{$input-height-inner} + calc(var(--duke-toggle-pwd-size) * 1.25em + var(--duke-toggle-pwd-size) * #{map-get($spacers, 2)}));
      // padding-right: add($input-height-inner, add(1.25em, map-get($spacers, 2)));

      & + .toggle-password {
        // make space for the validation icon
        right: $input-height-inner; // ($input-padding-x / 2);
        // moving the element may cause the intended click to miss the element, so we need to delay it slightly*
        // 150ms should be sufficient to cover most clicks (avg duration is 85ms)
        transition: right 100ms ease-in-out 150ms;
        margin-right: -($input-padding-x / 4);
      }
    }
  }
}

input.is-password {
  padding-right: calc(var(--duke-toggle-pwd-size) * 1.25em + #{$input-padding-x});
}

/**
 * control the visibility of MS show/hide pwd control that does not work at all with our own.
 */
::-ms-reveal {
  display: var(--duke-browser-toggle-pwd-display, initial);
}

.toggle-password {
  display: var(--duke-toggle-pwd-display);
  $pad: ($input-padding-x / 4);
  position: absolute;
  top: $input-padding-y;
  padding: 0 $pad;
  margin-top: 1px;
  margin-bottom: 0;
  margin-left: 0;

  right: $pad * 2;
  // not sure why, but 1px offset is required to align nicely
  margin-right: 0;

  border: 0 none transparent;
  background: transparent;
  color: $input-color;
  opacity: 0.65;
  border-radius: $input-border-radius;
  font-size: $input-font-size;
  @include hover-focus() {
    opacity: 1;
    color: $link-color
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 $input-focus-width rgba($link-color, .25);
  }

  .fa {
  }
}

.form-control-lg,
.input-group-lg {
  input.is-password {
    padding-right: calc(var(--duke-toggle-pwd-size) * 1.25em + #{$input-padding-x-lg});
  }

  & + .toggle-password {

    top: $input-padding-y-lg;
    right: ($input-padding-x-lg / 2);
    padding: 0 ($input-padding-x-lg / 4);
    border-radius: $input-border-radius-lg;
    font-size: $input-font-size-lg;
  }

  @if ($enable-validation-icons) {
    &.is-invalid,
    &.is-valid {
      & + .toggle-password {
        margin-right: -($input-padding-x-lg / 4);
      }
    }
  }

}
.form-control-sm,
.input-group-sm {
  input.is-password {
    padding-right: calc(var(--duke-toggle-pwd-size) * 1.25em + #{$input-padding-x-sm});
  }

  & + .toggle-password {

    top: $input-padding-y-sm;
    right: ($input-padding-x-sm / 2);
    padding: 0 ($input-padding-x-sm / 4);
    border-radius: $input-border-radius-sm;
    font-size: $input-font-size-sm;
  }

  @if ($enable-validation-icons) {
    &.is-invalid,
    &.is-valid {
      & + .toggle-password {
        margin-right: -($input-padding-x-sm / 4);
      }
    }
  }
}

