
$duke-icon-highlight-color: if($primary == $card-bg, $body-color, $primary) !default;
$duke-profile-pic-bg-color: $duke-icon-highlight-color !default;
@function _dukeDefaultProfilePicColor() {
  @if $duke-color-scheme == "dark" {
    @return color-yiq($duke-profile-pic-bg-color, $card-bg, $body-color);
  } @else {
    @return color-yiq($duke-profile-pic-bg-color, $body-color, $card-bg);
  }
}
$duke-profile-pic-color: _dukeDefaultProfilePicColor() !default;//  !default;
$duke-profile-pic-bg-image: none !default;
$duke-hide-icons: false !default;
.no-icon
{
  @if ($duke-hide-icons == false) {
    display: none!important;
  }
}
.icon
{
  // makes icons look a bit crisper, helps especially when there is not enought contrast with bg
  -webkit-text-stroke: 0.5px rgba(0,0,0,0.25);
  @if $duke-hide-icons {
    display: none!important;
  }
  &.highlight
  {
    color: $duke-icon-highlight-color;
  }
}
.profile-picture
{
  -webkit-text-stroke: 0.5px rgba(0,0,0,0.25);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  background-color: $duke-profile-pic-bg-color;
  background-image: $duke-profile-pic-bg-image;
  color: $duke-profile-pic-color;
  font-family: $font-family-monospace;
  font-size: 1.81818em;
  text-align: center;
  width: 1.1em;
  height: 1.1em;
  line-height: 1.15em;
  border-radius: 1.1em;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
  display: inline-block;
  text-rendering: auto;
}
