
.card-footer
{
  border-top: 0 transparent none;
}
.card-header
{
  border-bottom: 0 transparent none;
}

a.card:hover {
  text-decoration: none;
}
$duke-standalone-card-border-color: $card-bg !default;
.card.standalone {
  border-color: $duke-standalone-card-border-color;
  &.show {
    @include box-shadow($box-shadow);
    @include transition($transition-base);
    z-index: 2;
  }
  margin: 0 auto;

  @include media-breakpoint-down(xs) {
    border-left: 0 transparent none;
    border-right: 0 transparent none;
    border-radius: 0;
    &,
    &.show {
      @include box-shadow($box-shadow-sm);
    }
    &:not(.bg-danger) .card-body .card-body,
    &:not(.bg-danger) .card-body .card-header,
    &:not(.bg-danger) .card-body .card-footer {
      padding-left: $card-spacer-x;
      padding-right: $card-spacer-x;
    }
  }
}
