/**
 * black = 0, white = 255
 */
@function duke_yiq($color) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;
  @return $yiq;
}

@function duke_theme-color-level-mix($color-name: "primary", $level: 0, $color-base: $card-bg) {
  $color: theme-color($color-name);
  $level: abs($level);

  @return mix($color-base, $color, $level * $theme-color-interval);
}


@function gcd($a, $b) {
  // From: http://rosettacode.org/wiki/Greatest_common_divisor#JavaScript
  @if ($b != 0) {
    @return gcd($b, $a % $b);
  } @else {
    @return abs($a);
  }
}

@function pow($base, $exponent, $prec: 12) {
  // Handles decimal exponents by trying to convert them into a fraction and then use a nthRoot-algorithm for parts of the calculation
  @if (floor($exponent) != $exponent) {
    $prec2 : pow(10, $prec);
    $exponent: round($exponent * $prec2);
    $denominator: gcd($exponent, $prec2);
    @return nthRoot(pow($base, $exponent / $denominator), $prec2 / $denominator, $prec);
  }

  $value: $base;
  @if $exponent > 1 {
    @for $i from 2 through $exponent {
      $value: $value * $base;
    }
  } @else if $exponent < 1 {
    @for $i from 0 through -$exponent {
      $value: $value / $base;
    }
  }

  @return $value;
}

@function nthRoot($num, $n: 2, $prec: 12) {
  // From: http://rosettacode.org/wiki/Nth_root#JavaScript
  $x: 1;

  @for $i from 0 through $prec {
    $x: 1 / $n * (($n - 1) * $x + ($num / pow($x, $n - 1)));
  }

  @return $x;
}
$luminance-cache: ();
/**
 * Execution is very slow, so cache all results and reuse when needed.
 */
@function color_luminance($color) {
  // Adapted from: https://github.com/LeaVerou/contrast-ratio/blob/gh-pages/color.js
  // Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef

  $result: map-get($luminance-cache, $color);
  @if ($result) {
    @return $result;
  }
  $rgba: red($color), green($color), blue($color);
  $rgba2: ();

  @for $i from 1 through 3 {
    $rgb: nth($rgba, $i);
    $rgb: $rgb / 255;

    $rgb: if($rgb < .03928, $rgb / 12.92, pow(($rgb + .055) / 1.055, 2.4));

    $rgba2: append($rgba2, $rgb);
  }
  $result: 0.2126 * nth($rgba2, 1) + 0.7152 * nth($rgba2, 2) + 0.0722 * nth($rgba2, 3);
  $luminance-cache: map-merge($luminance-cache, ($color: $result)) !global;
  @return $result;
}

@function color_contrast($color1, $color2) {
  // Adapted from: https://github.com/LeaVerou/contrast-ratio/blob/gh-pages/color.js
  // Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
  $luminance1: color_luminance($color1) + .05;
  $luminance2: color_luminance($color2) + .05;
  $ratio: $luminance1 / $luminance2;

  @if $luminance2 > $luminance1 {
    $ratio: 1 / $ratio;
  }

  $ratio: round($ratio * 10) / 10;

  @return $ratio;
}


$yiq-minimum-contrast: 100 !default;
//$lighten: $duke-color-scheme == 'dark'
//$lighten: duke_yiq($background) < 128
$contrasted-colors: ();
@function duke_contrasted-color($color, $background, $lighten: duke_yiq($background) < 128) {
  $color-map: map-get($contrasted-colors, "#{$color} #{$background} #{$lighten}");
  @if ($color-map) {
    // @debug "found from map: #{$color-map}";
    @return $color-map;
  }

  $result: $color;
  $result-contrast: color_contrast($result, $background);
  $candidate: $color;
  $candidate-contrast: $result-contrast;
  $second-pass: false;
  // The resulting contrast is sometimes slightly less than the requirement, likely due to rounding errors.
  $required-contrast: 7;
  $step-size: 5%;
  @while($candidate-contrast < $required-contrast) {
    @if $lighten {
      // @debug "lightening";
      $candidate: lighten($candidate, $step-size);
    } @else {
      // @debug "darkening";
      $candidate: darken($candidate, $step-size);
    }
    $candidate-contrast: color_contrast($candidate, $background);
    @if($candidate-contrast > $result-contrast) {
      // @debug "better color found: #{$candidate}, #{$candidate-contrast}";
      $result: $candidate;
      $result-contrast: $candidate-contrast;
    }
    // for some reason color comparison does not work, instead we must comapre the color components
    @if($candidate-contrast >= $required-contrast) {
      // @debug "sufficient contrast";
      $contrasted-colors: map-merge($contrasted-colors, ('#{$color} #{$background} #{$lighten}': $result)) !global;
      @return $result;
    } @else if($lighten and red($candidate) == red(white) and green($candidate) == green(white) and blue($candidate) == blue(white)) {
      // @debug "max reached, #{$result} #{$candidate}";
      @if ($second-pass) {
        $contrasted-colors: map-merge($contrasted-colors, ('#{$color} #{$background} #{$lighten}': $result)) !global;
        @return $result;
      } @else {
        $second-pass: true;
        $lighten: false;
        $candidate: $color;
        $candidate-contrast: color_contrast($candidate, $background);
      }
    } @else if($lighten == false and red($candidate) == red(black) and green($candidate) == green(black) and blue($candidate) == blue(black)) {
      // @debug "min reached, #{$result} #{$candidate}";
      @if ($second-pass) {
        $contrasted-colors: map-merge($contrasted-colors, ('#{$color} #{$background} #{$lighten}': $result)) !global;
        @return $result;
      } @else {
        $second-pass: true;
        $lighten: true;
        $candidate: $color;
        $candidate-contrast: color_contrast($candidate, $background);
      }
    }
  }
  // @debug "Original had sufficient contrast, #{$result}";
  $contrasted-colors: map-merge($contrasted-colors, ('#{$color} #{$background} #{$lighten}': $result)) !global;
  @return $result;
}



