// 10Duke brand

$duke-color-scheme:                 "light";

$primary:                           #00558c;  // dark blue
$secondary:                         #41b6e6;  // light blue
$success:                           #76ab0d;  // (no change) dull green
$danger:                            #bf0d3e;  // dark red
$info:                              #563d82;  // dark purple
$warning:                           #e6a205;  // (no change) yellowish orange

$light:                             #f7f9fb;  // seasalt white

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i&display=swap');
$headings-font-family:              "Montserrat", sans-serif;

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,700,700i&display=swap');
$font-family-base:            "Source Sans Pro", sans-serif;

@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:700&display=swap');
$font-family-monospace:             "Roboto Mono", monospace;

$duke-icon-highlight-color:         $info;

// Gradient with shades of the info purple
$duke-profile-pic-bg-image:         linear-gradient(165deg, rgba(#FFF, 0.1) 0%, rgba(#FFF, 0.25) 49%, rgba(#FFF, 0) 51%), linear-gradient(75deg, lighten($info, 5%) 0%, darken($info, 5%) 100%);

// Buttons are filled only when hovering over them
$duke-outline-buttons:              true;
