

html
{
  height: 100%;
  overflow: auto;
}
$duke-body-background: none !default;
body
{
  min-height:100%;
  overflow: auto;
  background: $duke-body-background;
}
svg
{
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
a:focus {
  outline-style: none;
}

article.container
{
  height: 100vh;
  body.embedded &
  {
    height: auto;
  }
  body.no-header &
  {
    padding-top: map-get($spacers, 5);
  }
  body.no-footer & {
    padding-bottom: map-get($spacers, 4);
  }
}

@include media-breakpoint-down(xs) {
  article.container
  {
    // max-width: calc(100vw - #{$grid-gutter-width}); //
    padding-left: 0;
    padding-right: 0;
    body.no-header &
    {
      padding-top: map-get($spacers, 4);
    }
  }
  article.container > header {
    flex: 1 0 auto;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }
  article.container > router-outlet ~ *:not(footer),
  #catastrophicFailure {
    flex: 1 1 auto;
  }
  article.container > footer {
    flex: 0 0 auto;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }

}
article.container > router-outlet ~ *:not(footer)
{
  flex-shrink: 0;
}
a.nav-link,
.nav-tabs a.nav-link,
.nav-tabs .nav-item.show a.nav-link
{
  color: $text-muted;
  &:hover,
  &:active,
  &:focus,
  &.active
  {
    color: $body-color;
  }

}

.session-panel
{
  position: fixed;
  top: 0;
  right: map_get($spacers, 3);
  z-index: $zindex-modal;
  .popover
  {
    min-width: 240px;
    max-width: calc(100vw - 2rem);
  }
  .popover.bs-popover-bottom.bs-popover-bottom-right
  {
    top: auto;
    left: auto;
    right: 0;
    transform-origin: 100% 0%;
    transform: scale(0);
    transition: transform 200ms ease-out;
    visibility: hidden;
    &.show
    {
      visibility: visible;
      transform: scale(1);
    }
    & .arrow
    {
      left: auto;
      right: 2em;
      margin-left: -0.5rem;
    }
  }
}
.localeNotification
{
  @extend %narrow-standalone-content;
}

.logo {
  @include transition($transition-base);
  opacity: 1;
  transform: scale(1, 1);
  &.loading {
    opacity: 0;
    transform: scale(0.95, 0.95);
  }
}

$duke-preferred-logo-height: 100px !default;
article.container > header
{
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .logo-holder {
    text-align: center;
    .logo {
      height: $duke-preferred-logo-height;
      display: inline-block;
      img,
      svg
      {
        max-height: 100%;
        max-width: 100%;
      }
    }
    a {
      &:focus {
        outline: none;
      }
      &::-moz-focus-inner {
        border: 0;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    &.has-session
    {
      // estimate to clear space for the session panel trigger
      margin-top: 3.85rem;
    }
    & {
      // align logo to bottom of header
      display: flex;
      align-items: flex-end;
    }
    .logo-holder .logo
    {
      height: auto;
      max-height: $duke-preferred-logo-height;
      img,
      svg
      {
        max-height: $duke-preferred-logo-height;
      }
    }
  }

}
article.container > footer {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  width: 100%;
  transition: max-width 200ms ease-out;
  .navbar
  {
    @if (duke_yiq($body-bg) > $yiq-contrasted-threshold/*$duke-color-scheme == "light"*/) {
      @extend .navbar-light;
    } @else {
      @extend .navbar-dark;
    }
    @include media-breakpoint-down(xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .navbar-brand,
  & .navbar-light .navbar-brand {
    text-align: center;
    @include font-size($small-font-size);
    &,
    &:focus,
    &:hover,
    &:active {
      color: $text-muted;
    }
  }
  .navbar .dropdown-toggle:after {
    vertical-align: baseline;
  }
  a {
    color: inherit;

    &:focus
    {

      outline-style: dotted;
      outline-color: rgba($link-color, 0.5)   ;
      outline-offset: 0.125rem;
      outline-width: 0.125rem;
    }
  }
}
$duke-tooltip-box-shadow:           none!default;
.tooltip.show .tooltip-inner
{
  @include box-shadow($duke-tooltip-box-shadow);
  color: color-yiq($tooltip-bg);
}
.tab-content
{
  position: relative;
  overflow: hidden;
  /*
    Create space for the input focus glow effect
  */
  .card-body & {
    padding: 5px;
    margin: -5px;
  }
}
.tab-content > .tab-pane
{
  display:block;
  // setting 100% explicitly breaks rendering on FF
  width: auto;
  top: -100%;
  overflow: hidden;
  position: absolute;
  max-height: 100%;
  visibility: hidden;
  &.active
  {
    top: 0;
    position: static;
    overflow: visible;
    max-height: unset;
    visibility: visible;
  }
}

.visual-link
{
  color: $link-color;
  text-decoration: $link-decoration;
  display:inline-block;
  a:hover &
  {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

}


#catastrophicFailure + footer,
#catastrophicFailure .standalone {
  @extend %narrow-standalone-content;
}

.nested-flex-fix-for-ie {
  flex: 1 1 0%;
}
.btn-facebook
{
  @if $duke-outline-buttons {
    @include button-outline-variant(#3b5998);
  } @else {
    @include button-variant(#3b5998, #3b5998);
  }
}
.btn-google
{
  @if $duke-outline-buttons {
    @include button-outline-variant(#DB4437);
  } @else {
    @include button-variant(#DB4437,#DB4437);
  }
}
.btn-adfs
{
  @if $duke-outline-buttons {
    @include button-outline-variant(#00a8e8);
  } @else {
    @include button-variant(#00a8e8,#00a8e8);
  }
}


// FinCAD specific

// Hide "Create account" link
.login-component .btn[data-test-registration-link] {
  display:none;
}
