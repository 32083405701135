$duke-outline-buttons: false !default;
.btn-auto {
  @if $duke-outline-buttons {
    border-color: rgba($body-color,0.10);
    @if $primary == $card-bg {
      @include hover {
        $tmp: darken($primary, 5%);
        border-color: $tmp;
        background-color: $tmp;
        color: duke_contrasted-color($light, $tmp);
      }
    } @else {
      @include hover {
        border-color: $primary;
        background-color: $primary;
        color: duke_contrasted-color($light, $primary);
      }
    }


  } @else if $primary == $card-bg {
    @include button-variant(rgba($body-color,0.10), transparent, darken($primary, 5%), darken($primary, 5%));
    color: duke_contrasted-color($light, mix($body-color, $card-bg, 10%));
  } @else {
    @include button-variant(rgba($body-color,0.10), transparent, $primary, $primary);
    color: duke_contrasted-color($light, mix($body-color, $card-bg, 10%));
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    $tmpbg: $value;
    // Override button color, if color is same as card bg
    @if $value == $card-bg {
      $tmpbg: darken($value, 5%);
      @include button-variant($tmpbg, $tmpbg);
    }
    // override text color for contrast
    $tmpcolor: duke_contrasted-color($light, $tmpbg);
    color: $tmpcolor;
    // override hover text color for contrast
    @include hover {
      color: duke_contrasted-color($light, darken($tmpbg, 7.5%));
    }
    // override disabled color for contrast
    &.disabled,
    &:disabled {
      color: $tmpcolor;
    }
    // override active color for contrast
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      color: duke_contrasted-color($light, darken($tmpbg, 10%));
    }
  }
  // override solid buttons as outline buttons
  @if $duke-outline-buttons {
    .btn-#{$color} {
      @include hover {
        // override hover state bg
        @if $value == $card-bg {
          background-color: darken($value, 5%);
        } @else {
          background-color: $value;
        }
        // override hover text color for contrast
        color: duke_contrasted-color($light, $value);
      }
      // override default and disabled state
      &,
      &.disabled,
      &:disabled {
        background-color: transparent;
        box-shadow: none;
        @if $color != $duke-color-scheme {
          @if $value == $card-bg {
            color: duke_contrasted-color(darken($value, 5%), $card-bg);
          } @else {
            color: duke_contrasted-color($value, $card-bg);
          }
        } @else {
          color: duke_contrasted-color($value, $card-bg);
        }
      }
    }
  } @else if $duke-alert-style == 'solid' {
    // override button color on same color bg
    .bg-#{$color} .btn-#{$color},
    .alert-#{$color} .btn-#{$color}
    {
      @include button-variant(darken($value, 5%), darken($value, 5%));
      color: duke_contrasted-color($light, darken($value, 5%));
    }
  } @else {
    // override button color on same color bg
    .bg-#{$color} .btn-#{$color}
    {
      @include button-variant(darken($value, 5%), darken($value, 5%));
      color: duke_contrasted-color($light, darken($value, 5%));
    }
  }
  // override text color and colros on same color bg
  .btn-outline-#{$color} {
    @if $color != $duke-color-scheme {
      @if $value == $card-bg {
        $t: darken($value, 5%);
        $ct: duke_contrasted-color($t, $card-bg);
        @include button-outline-variant($ct, color-yiq($t), $t, $t);
      } @else {
        color: duke_contrasted-color($value, $card-bg);
      }
    }
  }
  @if $duke-alert-style == 'solid' {
    .bg-#{$color},
    .alert-#{$color}
    {
      // override same color button
      & .btn-outline-#{$color} {
        $t: #FFF;
        $ct: duke_contrasted-color($t, $card-bg);
        @include button-outline-variant($ct, color-yiq($t), $t, $t);
      }
      & .text-muted,
      & .text-#{$color}
      {
        color: inherit !important;
      }
      & .text-muted
      {
        opacity: 0.75;
      }
    }
  } @else {
    // override same color button
    .bg-#{$color} .btn-outline-#{$color}
    {
      $t: #FFF;
      $ct: duke_contrasted-color($t, $card-bg);
      @include button-outline-variant($ct, color-yiq($t), $t, $t);
    }
  }
  // override text colors
  .text-#{$color} {
    @if $color != $duke-color-scheme {
      color: duke_contrasted-color($value, $card-bg) !important;
    }
  }
}
.input-group .btn
{
  border-color: $input-border-color;
}
.btn-link
{
  &:hover,
  &:focus
  {
    background-color: rgba($body-color, 0.05);// mix(map-get($duke-default-body-colors, $duke-color-scheme), $body-bg, 15%);
    color: color-yiq(mix($body-color, $body-bg, 0.05));
  }
  &:focus
  {
    @include box-shadow(inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba($link-hover-color, 0.5));
  }
}
